import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import OfferCheck from "../../components/OfferCheck";
import HowDoesItWork from "../../components/HowDoesItWork";
import FantasticNumbersBar from "../../components/FantasticNumbersBar";

const UmschuldungKonditionencheck = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <OfferCheck
                defaultTerm={35}
                defaultMortgage={320000}
                defaultUsersOffer={1024}
                useH1
                calcCTA="Angebot sichern"
                hasGreyBg
                isRefinancing
                h1Title="Umschuldung Konditionencheck"
                hasMarginTop
            />
            <Cooperation hasMarginTop />
            <HowDoesItWork hasMarginTop hasGreyBg />
            <FantasticNumbersBar hasMarginTop />
            <BreadcrumbList page={"umschuldungKonditionencheck"}></BreadcrumbList>
        </Layout>
    );
};

export default UmschuldungKonditionencheck;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.dienstleistungen.konditionencheck"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
